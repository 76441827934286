<template>
  <div class="admin-edit-product" v-if="!loading">
    <b-tabs @input="changeTab">
      <b-tab title="General">
        <div class="row">
          <div class="col-4" :class="{ 'form-error': $v.productInternal.product_id.$error }">
            <label for="product_id" class="device-label">Product Id</label>
            <input type="text" v-model="$v.productInternal.product_id.$model" class="text-field w-input w-100" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="Stripe Product"
                   id="product_id">
            <div class="input-error" v-if="!$v.productInternal.product_id.required">Field is Required</div>
            <div class="input-error"
                 v-if="!$v.productInternal.product_id.minLength || !$v.productInternal.product_id.maxLength">Must be 10
              digits
            </div>
          </div>
          <div class=" col-6" :class="{ 'form-error': $v.productInternal.name.$error }">
            <label for="user_name" class="device-label">Product Name</label>
            <input type="text" v-model="$v.productInternal.name.$model" class="text-field w-input w-100" disabled
                   maxlength="256" placeholder="Full Name" id="user_name">
            <div class="input-error" v-if="!$v.productInternal.name.required">Field is Required</div>
          </div>
          <div class="col-1">
            <label for="index_hint" class="device-label">Index Hint</label>
            <input v-model="indexHint" class="text-field w-input w-100" type="number"
                   v-b-tooltip="'Optional Index Hint for Ordering in product selector'"
                   maxlength="256" placeholder="Index Hint" id="index_hint">
          </div>
          <div class="col-12" :class="{ 'form-error': $v.productInternal.description.$error }">
            <label for="description" class="device-label">Short Description</label>
            <input v-model="$v.productInternal.description.$model" class="text-field w-input w-100"
                        maxlength="256" placeholder="Short Description" id="description">
            <div class="input-error" v-if="!$v.productInternal.description.required">Field is Required</div>
          </div>

<!--          <div class="col-12" :class="{ 'form-error': $v.productInternal.description.$error }">-->
<!--            <label for="description" class="device-label">HTML Description</label>-->
<!--            <b-textarea v-model="htmlDescription" class="text-field w-input w-100"-->
<!--                        maxlength="256" placeholder="HTML Description" id="description"></b-textarea>-->
<!--            <div class="input-error" v-if="!$v.productInternal.description.required">Field is Required</div>-->
<!--          </div>-->

          <div class="col-3" :class="{ 'form-error': $v.productInternal.default_price_id.$error }">
            <label for="price_id" class="device-label">Default Price Id</label>
            <input type="text" v-model="$v.productInternal.default_price_id.$model" class="text-field w-input w-100"
                   maxlength="256" name="Registration" data-name="Registration" placeholder="Stripe Product"
                   id="price_id">
            <div class="input-error" v-if="!$v.productInternal.default_price_id.required">Field is Required</div>
            <div class="input-error"
                 v-if="!$v.productInternal.default_price_id.minLength || !$v.productInternal.default_price_id.maxLength">
              Must be 10 digits
            </div>
          </div>
          <div class="col-3">
            <label for="price_interval" class="device-label">Stripe Price Interval Count</label>
            <input type="text" v-model="productInternal.price_interval_count" class="text-field w-input w-100" disabled
                   maxlength="256" name="Registration" data-name="Registration"
                   placeholder="Stripe Price Interval Count" id="price_interval">
          </div>
          <div class="col-3">
            <label for="price_interval_unit" class="device-label">Stripe Price Interval Unit</label>
            <input type="text" v-model="productInternal.price_interval_unit" class="text-field w-input w-100" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="Stripe Price Interval Unit"
                   id="price_interval_unit">
          </div>
          <div class="col-3">
            <label for="price_usage_type" class="device-label">Stripe Price Usage Type</label>
            <input type="text" v-model="productInternal.price_usage_type" class="text-field w-input w-100" disabled
                   maxlength="256" name="Registration" data-name="Registration" placeholder="Stripe Price Interval Unit"
                   id="price_usage_type">
          </div>
          <div class="spacing"></div>
        </div>
        <div class="row">
          <div class=" col-4">
            <label class="device-label">Published</label>
            <b-form-checkbox size="lg" name="check-button" switch
                             v-model="productInternal.published"> Published
            </b-form-checkbox>
          </div>
          <div class="col-4">
            <label class="device-label">Product Type</label>
            <b-select id="b-select-one" class="w-100 text-field w-input"
                      v-model="$v.productInternal.product_type.$model">
              <b-select-option :value="null" disabled>-Select Unit-</b-select-option>
              <b-select-option value="primary">Primary</b-select-option>
              <b-select-option value="addon">Add-On</b-select-option>
            </b-select>
          </div>
          <div class="col-4">
            <label class="device-label">Billing Unit</label>
            <b-select id="b-select-one" class="w-100 text-field w-input" :disabled="!enableMetricSelect"
                      v-model="$v.productInternal.billable_unit.$model">
              <b-select-option :value="null" disabled>-Select Unit-</b-select-option>
              <b-select-option v-if="!enableMetricSelect" value="flat_rate">Flat Rate</b-select-option>
              <b-select-option value="tracked_assets">Tracked Assets</b-select-option>
              <b-select-option value="untracked_assets">Untracked Assets (not Implemented)</b-select-option>
              <b-select-option value="all_assets">All Assets (not Implemented)</b-select-option>
              <b-select-option value="users">Users (not Implemented)</b-select-option>
            </b-select>
          </div>
          <div class="col-4">
            <label class="device-label">Product Icon</label>
            <IconSelector v-model="productIcon"></IconSelector>
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            Last Updated: {{ dt.toLocalDateTime(productInternal.last_update) }}
          </div>
        </div>
        <b-alert show v-for="(alert, idx) of alerts" v-bind:key="idx"
           :variant="alert.variant">{{ alert.text }}</b-alert>
      </b-tab>
      <b-tab title="HTML Description">
        <h3>Preview:</h3>
        <div v-if="productPreview">
          <product-avatar :product="productPreview" :selected="previewSelected"
                          @click="previewSelected = !previewSelected"></product-avatar>
        </div>
        <h3>HTML Description</h3>
        <div class="html-editor">
          <codemirror ref="uiEditor" v-model="htmlDescription" :options="cmOptions">
          </codemirror>
        </div>
      </b-tab>
      <b-tab title="Valid Addons" v-if="isPrimaryProduct">
        <div class="hint-box">This tab controls which 'AddOn' products can be selected by the user for this product.
          This is only relevant if the product is of type 'Primary' (e.g. Is a Subscription Tier)
        </div>
        <b-form-checkbox-group :options="addonOptions" v-model="validAddons"></b-form-checkbox-group>
      </b-tab>
      <b-tab title="Roles">
        <div class="hint-box">This tab controls which roles the Product will grant to users.</div>
        <admin-roles-list :roles="productInternal.roles" :show-fields="roleFields" ref="rolesList"></admin-roles-list>
      </b-tab>
    </b-tabs>
    <div class="footer mt-2">
      <b-button class="button" @click="clickClose()">Close</b-button>
      <b-button class="button" @click="saveChanges()">Save Changes</b-button>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import {required, minLength, maxLength} from 'vuelidate/lib/validators'
// import * as AlertHelper from '../helpers/AlertHelper'
import AdminRolesList from './AdminRolesList'
import {DateTimeHelper as dt} from '@/components/helpers/DateTimeHelper'

// CODEMIRROR IMPORTS
import 'codemirror/lib/codemirror.css'
// language
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/mode/javascript/javascript'
// theme css
import 'codemirror/theme/monokai.css'
// require active-line.js
// styleSelectedText
import 'codemirror/addon/selection/mark-selection.js'
import 'codemirror/addon/search/searchcursor.js'
// hint
import 'codemirror/addon/hint/show-hint.js'
import 'codemirror/addon/hint/show-hint.css'
import 'codemirror/addon/hint/javascript-hint.js'
import 'codemirror/addon/selection/active-line.js'
// highlightSelectionMatches
import 'codemirror/addon/scroll/annotatescrollbar.js'
import 'codemirror/addon/search/matchesonscrollbar.js'
import 'codemirror/addon/search/match-highlighter.js'
// keyMap
import 'codemirror/mode/clike/clike.js'
import 'codemirror/addon/edit/matchbrackets.js'
import 'codemirror/addon/comment/comment.js'
import 'codemirror/addon/dialog/dialog.js'
import 'codemirror/addon/dialog/dialog.css'
import 'codemirror/addon/search/search.js'
import 'codemirror/keymap/sublime.js'
// foldGutter
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/brace-fold.js'
import 'codemirror/addon/fold/comment-fold.js'
import 'codemirror/addon/fold/foldcode.js'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'
import 'codemirror/addon/fold/markdown-fold.js'
import 'codemirror/addon/fold/xml-fold.js'
import ProductAvatar from '@/components/ProductAvatar.vue'
import IconSelector from '@/components/shared/IconSelector.vue'

export default {
  name: 'admin-edit-product',
  components: {
    IconSelector,
    ProductAvatar,
    AdminRolesList
  },
  props: {
    isModal: Boolean,
    modal: String,
    product: Object
  },
  data: function () {
    return {
      loading: true,
      products: null,
      showButtons: true,
      selectedDevices: null,
      dt: dt,
      productInternal: {
        selected: false,
        id: null,
        name: 'Bob Smith',
        published: false,
        roles: [],
        product_id: '',
        default_price_id: '',
        last_update: '',
        data: {
          html_description: '',
          valid_addons: [],
          product_icon: null
        }
      },
      roleFields: ['id', 'name', 'grants', 'actions'],
      htmlDescription: null,
      validAddons: [],
      cmOptions: {
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: false,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        mode: 'text/html',
        // hint.js options
        hintOptions: {
          completeSingle: false
        },
        keyMap: 'sublime',
        matchBrackets: true,
        showCursorWhenSelecting: true,
        theme: 'monokai',
        extraKeys: {
          'Ctrl': 'autocomplete',
          'Ctrl-S': () => this.saveChanges()
        }
      },
      previewSelected: false,
      indexHint: null,
      productIcon: null
    }
  },
  validations: {
    productInternal: {
      name: {
        required
      },
      description: {
      },
      product_id: {
        required,
        minLength: minLength(19),
        maxLength: maxLength(19)
      },
      default_price_id: {
        required,
        minLength: minLength(30),
        maxLength: maxLength(30)
      },
      product_type: {
        required
      },
      billable_unit: {
        required
      }
    }
  },
  async created() {
    this.products = await this.getProducts()
    if (this.product) {
      console.log(this.product)
      this.productInternal = {...this.product} // Take a shallow copy to avoid editing the parent component's data directly
      // Note: If we don't at least take a shallow copy we can end up with unexpected behavior such as unsaved changes
      // being visible on the parent component, since JS passes by reference.
      if (this.productInternal.data === null) {
        this.productInternal.data = {}
      }
      this.htmlDescription = this.productInternal.data.html_description || ''
      this.validAddons = this.productInternal.data.valid_addons || []
      this.indexHint = this.productInternal.data.index_hint || null
      this.productIcon = this.productInternal.data.product_icon || null
    } else {
      this.productInternal = {
        selected: false,
        userId: '-1',
        devices: [],
        notifications: [],
        email: '',
        mobile: '',
        name: '',
        activated: false,
        admin: false,
        roles: [],
        lastLogin: '',
        data: {}
      }
    }
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.loading = false
  },
  methods: {
    async getProducts() {
      let result = await DataProvider.getProducts()
      console.log('Products: ', result)
      if (result.success) {
        return result.data
      } else {
        ErrorHelper.displayDataErrorToast(result)
      }
    },
    saveChanges: async function () {
      let res
      if (this.$v.$anyError) {
        ErrorHelper.displayGeneralErrorToast('Some Fields Contain Invalid Data. Please fix them.', 'Invalid Fields')
        return
      }
      let roleIds = this.productInternal.roles.filter(x => x.grants).map(x => x.id)
      console.log(roleIds)
      let data = {
        html_description: this.htmlDescription,
        valid_addons: this.validAddons,
        index_hint: this.indexHint,
        product_icon: this.productIcon
      }
      res = await DataProvider.adminUpdateProduct(
        this.productInternal.id,
        this.productInternal.description,
        this.productInternal.published,
        this.productInternal.product_type,
        this.productInternal.billable_unit,
        roleIds,
        data
      )
      if (res.success) {
        this.$v.$reset()
        this.$refs.rolesList.dirty = false
        this.$bvToast.toast('The changes you made have been saved!',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    clickClose: async function () {
      console.log(this.productInternal)
      if (this.$v.$anyDirty || this.$refs.rolesList.dirty) {
        let res = await this.$bvModal.msgBoxConfirm('You have unsaved changes, are you sure you want to close this window?', {
          title: 'Confirm Discard Changes',
          okVariant: 'warning',
          centered: true
        })
        if (!res) {
          return
        }
      }
      this.$emit('save')
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    changeTab(tabIdx) {
      console.log(this.htmlDescription)
      if (tabIdx === 1 && this.$refs.uiEditor) {
        // Hack to make CodeMirror handle changing tabs and resizing properly
        this.$refs.uiEditor.refresh()
      }
    }
  },
  computed: {
    enableMetricSelect() {
      return this.productInternal.price_usage_type !== 'licensed'
    },
    alerts() {
      let alerts = []
      if (!this.productInternal.product_type) {
        alerts.push({
          variant: 'danger',
          text: 'This product will not be visible to users, even when "Published" is ticked, until a PRODUCT TYPE ' +
            'is selected.'
        })
      }
      if (this.productInternal.price_usage_type === 'licensed') {
        alerts.push({
          variant: 'info',
          text: 'The Stripe product this product is linked to is set to use "licensed" pricing. That means is will work' +
            ' as a fixed price product,so you cant set a Billing Unit.'
        })
      }
      if (!this.productInternal.billable_unit) {
        alerts.push({
          variant: 'danger',
          text: 'This product will not be visible to users, even when "Published" is ticked, until a BILLING UNIT ' +
            'is selected.'
        })
      }
      console.log(alerts)
      return alerts
    },
    addonOptions () {
      if (this.products) {
        return this.products.filter(p => p.product_type === 'addon').map(p => {
          return {
            value: p.product_id,
            text: `${p.name} (${p.product_id})`
          }
        })
      } else {
        return []
      }
    },
    isPrimaryProduct () {
      return this.productInternal.product_type === 'primary'
    },
    productPreview() {
      let product = { ...this.productInternal }
      product.data = {
        html_description: this.htmlDescription,
        valid_addons: this.validAddons,
        product_icon: this.productIcon
      }
      console.log('Product Preview: ', product)
      return product
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../variables';

.admin-edit-product {
  color: $text-color-invert;
}

.device-label {
  font-family: 'Open Sans', sans-serif;
  color: $theme-color-primary-3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.device-code {
  color: $text-color-invert;
  margin-left: 5%;
}

.toolbar {
  display: flex;
  flex-direction: row;
}

.icon-button, .icon-button-danger {
  font-size: 1.9rem;
}

.footer {
  width: 100%;
  bottom: 2%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1% 0 1%;
}

.icon_select_color {
  border: solid $theme-color-primary-3;
}

.notification-container {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow-y: auto;
}

.notification-item {
  background: $theme-color-background-1;
}

.html-editor {
  position: relative;
}

codemirror {
  min-height: 8em;
}


</style>
