var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "admin-edit-product" },
        [
          _c(
            "b-tabs",
            { on: { input: _vm.changeTab } },
            [
              _c(
                "b-tab",
                { attrs: { title: "General" } },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-4",
                        class: {
                          "form-error": _vm.$v.productInternal.product_id.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "device-label",
                            attrs: { for: "product_id" }
                          },
                          [_vm._v("Product Id")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.productInternal.product_id.$model,
                              expression: "$v.productInternal.product_id.$model"
                            }
                          ],
                          staticClass: "text-field w-input w-100",
                          attrs: {
                            type: "text",
                            disabled: "",
                            maxlength: "256",
                            name: "Registration",
                            "data-name": "Registration",
                            placeholder: "Stripe Product",
                            id: "product_id"
                          },
                          domProps: {
                            value: _vm.$v.productInternal.product_id.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.productInternal.product_id,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        !_vm.$v.productInternal.product_id.required
                          ? _c("div", { staticClass: "input-error" }, [
                              _vm._v("Field is Required")
                            ])
                          : _vm._e(),
                        !_vm.$v.productInternal.product_id.minLength ||
                        !_vm.$v.productInternal.product_id.maxLength
                          ? _c("div", { staticClass: "input-error" }, [
                              _vm._v("Must be 10 digits ")
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-6",
                        class: {
                          "form-error": _vm.$v.productInternal.name.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "device-label",
                            attrs: { for: "user_name" }
                          },
                          [_vm._v("Product Name")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.productInternal.name.$model,
                              expression: "$v.productInternal.name.$model"
                            }
                          ],
                          staticClass: "text-field w-input w-100",
                          attrs: {
                            type: "text",
                            disabled: "",
                            maxlength: "256",
                            placeholder: "Full Name",
                            id: "user_name"
                          },
                          domProps: {
                            value: _vm.$v.productInternal.name.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.productInternal.name,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        !_vm.$v.productInternal.name.required
                          ? _c("div", { staticClass: "input-error" }, [
                              _vm._v("Field is Required")
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c("div", { staticClass: "col-1" }, [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "index_hint" }
                        },
                        [_vm._v("Index Hint")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.indexHint,
                            expression: "indexHint"
                          },
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip",
                            value:
                              "Optional Index Hint for Ordering in product selector",
                            expression:
                              "'Optional Index Hint for Ordering in product selector'"
                          }
                        ],
                        staticClass: "text-field w-input w-100",
                        attrs: {
                          type: "number",
                          maxlength: "256",
                          placeholder: "Index Hint",
                          id: "index_hint"
                        },
                        domProps: { value: _vm.indexHint },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.indexHint = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "col-12",
                        class: {
                          "form-error":
                            _vm.$v.productInternal.description.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "device-label",
                            attrs: { for: "description" }
                          },
                          [_vm._v("Short Description")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.productInternal.description.$model,
                              expression:
                                "$v.productInternal.description.$model"
                            }
                          ],
                          staticClass: "text-field w-input w-100",
                          attrs: {
                            maxlength: "256",
                            placeholder: "Short Description",
                            id: "description"
                          },
                          domProps: {
                            value: _vm.$v.productInternal.description.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.productInternal.description,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        !_vm.$v.productInternal.description.required
                          ? _c("div", { staticClass: "input-error" }, [
                              _vm._v("Field is Required")
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-3",
                        class: {
                          "form-error":
                            _vm.$v.productInternal.default_price_id.$error
                        }
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "device-label",
                            attrs: { for: "price_id" }
                          },
                          [_vm._v("Default Price Id")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.$v.productInternal.default_price_id.$model,
                              expression:
                                "$v.productInternal.default_price_id.$model"
                            }
                          ],
                          staticClass: "text-field w-input w-100",
                          attrs: {
                            type: "text",
                            maxlength: "256",
                            name: "Registration",
                            "data-name": "Registration",
                            placeholder: "Stripe Product",
                            id: "price_id"
                          },
                          domProps: {
                            value:
                              _vm.$v.productInternal.default_price_id.$model
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.$v.productInternal.default_price_id,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        !_vm.$v.productInternal.default_price_id.required
                          ? _c("div", { staticClass: "input-error" }, [
                              _vm._v("Field is Required")
                            ])
                          : _vm._e(),
                        !_vm.$v.productInternal.default_price_id.minLength ||
                        !_vm.$v.productInternal.default_price_id.maxLength
                          ? _c("div", { staticClass: "input-error" }, [
                              _vm._v(" Must be 10 digits ")
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "price_interval" }
                        },
                        [_vm._v("Stripe Price Interval Count")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.productInternal.price_interval_count,
                            expression: "productInternal.price_interval_count"
                          }
                        ],
                        staticClass: "text-field w-input w-100",
                        attrs: {
                          type: "text",
                          disabled: "",
                          maxlength: "256",
                          name: "Registration",
                          "data-name": "Registration",
                          placeholder: "Stripe Price Interval Count",
                          id: "price_interval"
                        },
                        domProps: {
                          value: _vm.productInternal.price_interval_count
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.productInternal,
                              "price_interval_count",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "price_interval_unit" }
                        },
                        [_vm._v("Stripe Price Interval Unit")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.productInternal.price_interval_unit,
                            expression: "productInternal.price_interval_unit"
                          }
                        ],
                        staticClass: "text-field w-input w-100",
                        attrs: {
                          type: "text",
                          disabled: "",
                          maxlength: "256",
                          name: "Registration",
                          "data-name": "Registration",
                          placeholder: "Stripe Price Interval Unit",
                          id: "price_interval_unit"
                        },
                        domProps: {
                          value: _vm.productInternal.price_interval_unit
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.productInternal,
                              "price_interval_unit",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-3" }, [
                      _c(
                        "label",
                        {
                          staticClass: "device-label",
                          attrs: { for: "price_usage_type" }
                        },
                        [_vm._v("Stripe Price Usage Type")]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.productInternal.price_usage_type,
                            expression: "productInternal.price_usage_type"
                          }
                        ],
                        staticClass: "text-field w-input w-100",
                        attrs: {
                          type: "text",
                          disabled: "",
                          maxlength: "256",
                          name: "Registration",
                          "data-name": "Registration",
                          placeholder: "Stripe Price Interval Unit",
                          id: "price_usage_type"
                        },
                        domProps: {
                          value: _vm.productInternal.price_usage_type
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.productInternal,
                              "price_usage_type",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "spacing" })
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("label", { staticClass: "device-label" }, [
                          _vm._v("Published")
                        ]),
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: {
                              size: "lg",
                              name: "check-button",
                              switch: ""
                            },
                            model: {
                              value: _vm.productInternal.published,
                              callback: function($$v) {
                                _vm.$set(_vm.productInternal, "published", $$v)
                              },
                              expression: "productInternal.published"
                            }
                          },
                          [_vm._v(" Published ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("label", { staticClass: "device-label" }, [
                          _vm._v("Product Type")
                        ]),
                        _c(
                          "b-select",
                          {
                            staticClass: "w-100 text-field w-input",
                            attrs: { id: "b-select-one" },
                            model: {
                              value: _vm.$v.productInternal.product_type.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.productInternal.product_type,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.productInternal.product_type.$model"
                            }
                          },
                          [
                            _c(
                              "b-select-option",
                              { attrs: { value: null, disabled: "" } },
                              [_vm._v("-Select Unit-")]
                            ),
                            _c(
                              "b-select-option",
                              { attrs: { value: "primary" } },
                              [_vm._v("Primary")]
                            ),
                            _c(
                              "b-select-option",
                              { attrs: { value: "addon" } },
                              [_vm._v("Add-On")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("label", { staticClass: "device-label" }, [
                          _vm._v("Billing Unit")
                        ]),
                        _c(
                          "b-select",
                          {
                            staticClass: "w-100 text-field w-input",
                            attrs: {
                              id: "b-select-one",
                              disabled: !_vm.enableMetricSelect
                            },
                            model: {
                              value:
                                _vm.$v.productInternal.billable_unit.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.productInternal.billable_unit,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.productInternal.billable_unit.$model"
                            }
                          },
                          [
                            _c(
                              "b-select-option",
                              { attrs: { value: null, disabled: "" } },
                              [_vm._v("-Select Unit-")]
                            ),
                            !_vm.enableMetricSelect
                              ? _c(
                                  "b-select-option",
                                  { attrs: { value: "flat_rate" } },
                                  [_vm._v("Flat Rate")]
                                )
                              : _vm._e(),
                            _c(
                              "b-select-option",
                              { attrs: { value: "tracked_assets" } },
                              [_vm._v("Tracked Assets")]
                            ),
                            _c(
                              "b-select-option",
                              { attrs: { value: "untracked_assets" } },
                              [_vm._v("Untracked Assets (not Implemented)")]
                            ),
                            _c(
                              "b-select-option",
                              { attrs: { value: "all_assets" } },
                              [_vm._v("All Assets (not Implemented)")]
                            ),
                            _c(
                              "b-select-option",
                              { attrs: { value: "users" } },
                              [_vm._v("Users (not Implemented)")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("label", { staticClass: "device-label" }, [
                          _vm._v("Product Icon")
                        ]),
                        _c("IconSelector", {
                          model: {
                            value: _vm.productIcon,
                            callback: function($$v) {
                              _vm.productIcon = $$v
                            },
                            expression: "productIcon"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col s12" }, [
                      _vm._v(
                        " Last Updated: " +
                          _vm._s(
                            _vm.dt.toLocalDateTime(
                              _vm.productInternal.last_update
                            )
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _vm._l(_vm.alerts, function(alert, idx) {
                    return _c(
                      "b-alert",
                      { key: idx, attrs: { show: "", variant: alert.variant } },
                      [_vm._v(_vm._s(alert.text))]
                    )
                  })
                ],
                2
              ),
              _c("b-tab", { attrs: { title: "HTML Description" } }, [
                _c("h3", [_vm._v("Preview:")]),
                _vm.productPreview
                  ? _c(
                      "div",
                      [
                        _c("product-avatar", {
                          attrs: {
                            product: _vm.productPreview,
                            selected: _vm.previewSelected
                          },
                          on: {
                            click: function($event) {
                              _vm.previewSelected = !_vm.previewSelected
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("h3", [_vm._v("HTML Description")]),
                _c(
                  "div",
                  { staticClass: "html-editor" },
                  [
                    _c("codemirror", {
                      ref: "uiEditor",
                      attrs: { options: _vm.cmOptions },
                      model: {
                        value: _vm.htmlDescription,
                        callback: function($$v) {
                          _vm.htmlDescription = $$v
                        },
                        expression: "htmlDescription"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm.isPrimaryProduct
                ? _c(
                    "b-tab",
                    { attrs: { title: "Valid Addons" } },
                    [
                      _c("div", { staticClass: "hint-box" }, [
                        _vm._v(
                          "This tab controls which 'AddOn' products can be selected by the user for this product. This is only relevant if the product is of type 'Primary' (e.g. Is a Subscription Tier) "
                        )
                      ]),
                      _c("b-form-checkbox-group", {
                        attrs: { options: _vm.addonOptions },
                        model: {
                          value: _vm.validAddons,
                          callback: function($$v) {
                            _vm.validAddons = $$v
                          },
                          expression: "validAddons"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-tab",
                { attrs: { title: "Roles" } },
                [
                  _c("div", { staticClass: "hint-box" }, [
                    _vm._v(
                      "This tab controls which roles the Product will grant to users."
                    )
                  ]),
                  _c("admin-roles-list", {
                    ref: "rolesList",
                    attrs: {
                      roles: _vm.productInternal.roles,
                      "show-fields": _vm.roleFields
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer mt-2" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.clickClose()
                    }
                  }
                },
                [_vm._v("Close")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.saveChanges()
                    }
                  }
                },
                [_vm._v("Save Changes")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }