<template>
  <div class="product-list">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="green-divider"></div>
    <!--    Bootstrap Table-->
    <b-table striped hover :items="productList" :fields="productFields" select-mode="single" selected-variant="success"
             th-class="bv-table-header" :current-page="currentPage" :per-page="perPage"
             class="bv-table">
      <!--Activated Checkbox Row Element-->
      <template v-slot:cell(published)="row">
        <b-form-checkbox v-model="row.item.published" v-on:change="togglePublish(row.item, $event)"></b-form-checkbox>
      </template>
      <!--User Actions Row Element-->
      <template v-slot:cell(actions)="row">
        <i class="row-icon-button row-action" :class="$config.icons.general.edit" @click="productActionEdit(row.item)" v-b-tooltip.hover title="Edit Product"></i>
        <i class="fa fa-times-circle row-icon-button-danger row-action ml-3" v-b-tooltip.hover title="Does nothing!"
           @click="userActionDelete(row.item)"></i>
      </template>
    </b-table>
    <div class="green-divider"></div>
        <div class="page-bar">
          <b-form-select
            variant="dark"
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            class="page-select">
          </b-form-select>
          <b-pagination
            variant="dark"
            v-model="currentPage"
            :total-rows="productList.length"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="page-buttons"></b-pagination>
        </div>
    <!--   Product Edit Modal Template-->
    <b-modal id="modal-edit-product" centered class="modal-content" size="xl" hide-footer title="Edit Product" no-close-on-backdrop>
      <admin-edit-product is-modal modal="modal-edit-product" :product="currentProduct" v-on:save="onSave()"></admin-edit-product>
    </b-modal>
  </div>
</template>

<script>
import LoadingBox from '../helpers/LoadingBox'
import AdminEditProduct from './AdminEditProduct'
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import moment from 'moment'
import * as AlertHelper from '../helpers/AlertHelper'
export default {
  name: 'admin-product-list',
  components: {
    LoadingBox,
    AdminEditProduct
  },
  props: {
    products: Array
  },
  data: function () {
    return {
      loading: true,
      currentProduct: null,
      // Table Pagination
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      productFields: [
        { key: 'id',
          label: 'Id',
          sortable: true
        },
        { key: 'name',
          label: 'Name',
          sortable: true
        },
        { key: 'product_id',
          label: 'Product Id',
          sortable: true
        },
        { key: 'published',
          label: 'Published?',
          sortable: true
        },
        { key: 'last_update',
          label: 'Updated',
          sortable: true,
          formatter: this.formatTimestamp
        },
        { key: 'actions',
          label: 'Actions'
        }
      ],
      productList: []
    }
  },
  async created () {
    if (this.products) {
      this.productList = this.products
    } else {
      await this.refresh()
    }
    this.loading = false
  },
  methods: {
    refresh: async function () {
      let resp = await DataProvider.adminGetProducts()
      if (resp.success) {
        this.productList = resp.data
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    formatTimestamp: function (timestamp) {
      return moment.utc(timestamp).local().format('YYYY-MM-DD HH:mm')
    },
    togglePublish: async function (item, event) {
      console.log(event)
      let resp = await DataProvider.adminUpdateProduct(item.id, item.description, event)
      console.log(resp)
      if (resp.success) {
        AlertHelper.successToast('Product Publish Property Updated', 'Update Successful')
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    productActionEdit: function (product) {
      this.currentProduct = product
      this.$bvModal.show('modal-edit-product')
    },
    onSave: async function () {
      this.refresh()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .product-list{
    position: relative;
  }

  .filter-bar {
    display: flex;
    flex-direction: row;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  .row-action {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .child-card {
    background: $theme-color-background-4;
    padding: 10px 20px;
    border: 2px solid $theme-color-primary-3;
  }

  .advanced-filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    padding: 3px;
    background: $theme-color-background-1;
    border: 1px solid $theme-color-primary-3;
  }

  .filter-label {
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px 5px;
  }

  .sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: calc(1.5em + 0.5rem + 2px);
  }
</style>
